import { docReady } from "./utils";
import navbarInit from "./bootstrap-navbar";
import detectorInit from "./detector";
import scrollToTop from "./scroll-to-top";
import ytVideoInit from "./popup-yt-video";
import scrollbarInit from "./scrollbar";

// /* -------------------------------------------------------------------------- */
// /*                            Theme Initialization                            */
// /* -------------------------------------------------------------------------- */

docReady(navbarInit);
docReady(detectorInit);
docReady(scrollToTop);
docReady(ytVideoInit);
docReady(scrollbarInit);
