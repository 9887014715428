import BigPicture from "bigpicture";
import { isIterableArray } from "./utils";

/* -------------------------------------------------------------------------- */
/*                                Youtube Video                            */
/* -------------------------------------------------------------------------- */

const ytVideoInit = () => {
  const video = document.querySelectorAll("[data-yt-video]");
  const videoArr = Array.from(video);

  if (isIterableArray(videoArr)) {
    videoArr.forEach((vid) => {
      vid.addEventListener("click", () => {
        BigPicture({
          el: vid,
          ytSrc: vid.dataset.ytVideo,
        });
      });
    });
  }
};

export default ytVideoInit;
